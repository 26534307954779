<template>
    <div class="ml-10 mr-10">
        <TitleBar v-if="this.$route.params.uuid === 'new'" :loading="loading">{{ $t("credentials.new") }}</TitleBar>
        <TitleBar v-else
                  :disabled="loading"
                  :loading="loading"
                  :menu="[{text: this.$t('credentials.delete'), callback: deleteElement, class: 'red--text'}]"
        >{{ $t("credentials.edit") }}
        </TitleBar>

        <Tags v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Tags>

        <v-form @submit.prevent="formSubmit">

            <v-row>
                <v-col cols="12" md="6">
                    <DatePicker
                        v-model="expiry"
                        :disabled="loading"
                        :error-messages="expiryErrors"
                        :label="$t('credentials.expiry')"
                        @blur="$v.expiry.$touch()"
                        @input="$v.expiry.$touch()"
                    ></DatePicker>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select
                        v-model="permissions"
                        :disabled="loading"
                        :error-messages="permissionsErrors"
                        :items="available_permissions"
                        :label="$t('credentials.permissions')"
                        :no-data-text="$t('credentials.no_perms')"
                        chips
                        multiple
                        outlined
                        persistent-hint
                        @blur="$v.permissions.$touch()"
                        @input="$v.permissions.$touch()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-textarea
                v-model="description"
                :label="$t('credentials.description')"
                name="input-7-4"
                outlined
            ></v-textarea>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $route.params.uuid === "new" ? $t("credentials.add") : $t("credentials.update") }}
            </v-btn>
        </v-form>

        <!--        <v-btn-->
        <!--            v-if="this.$route.params.uuid !== 'new'"-->
        <!--            :loading="loading"-->
        <!--            class="mt-10"-->
        <!--            color="error"-->
        <!--            elevation="2"-->
        <!--            @click.prevent="deleteElement"-->
        <!--        >{{ $t("credentials.delete") }}-->
        <!--        </v-btn>-->

        <v-dialog
            v-model="show_dialog"
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t("credentials.tokens") }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <p>{{ $t("credentials.please_note") }}</p>

                        <v-text-field
                            v-model="token"
                            :label="$t('credentials.token')"
                            outlined
                            readonly
                        ></v-text-field>

                        <v-text-field
                            v-model="hash"
                            :label="$t('credentials.hash')"
                            outlined
                            readonly
                        ></v-text-field>

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        text
                        @click="show_dialog = false; $router.push({path: '/credentials/' + uuid})"
                    >
                        {{ $t("credentials.perfect") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Utils from "../../helpers/Utils";
import DatePicker from "../../components/DatePicker";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Details",
    components: {TitleBar, Tags, DatePicker},
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.credentials"),
                to: "/credentials",
                exact: true
            },
            {
                text: this.$route.params.uuid === "new" ? this.$t("credentials.new") : this.$t("credentials.edit"),
                to: "/credentials/" + this.$route.params.uuid,
                exact: true
            }
        ]);

        this.loading = true;
        let customers = await Accounting.get("/credentials/permissions").catch(() => {
        });
        this.available_permissions = customers.data.permissions;
        this.loading = false;

        if (this.$route.params.uuid !== "new") {
            this.loading = true;
            Accounting.get("/credentials/" + this.$route.params.uuid)
                .then(response => {
                    const data = response.data;
                    this.permissions = data.permissions;
                    this.expiry = data.expired_at;
                    this.description = data.description;
                    this.loading = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }


    },
    data: () => {
        return {
            loading: false,
            expiry: null,
            permissions: null,
            available_permissions: [],
            token: null,
            hash: null,
            show_dialog: false,
            uuid: null,
            description: null
        };
    },
    methods: {
        deleteElement() {
            this.$swal({
                title: this.$t("credentials.delete_title"),
                text: this.$t("credentials.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("credentials.delete_yes"),
                cancelButtonText: this.$t("credentials.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/credentials/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("credentials.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/credentials");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/credentials";

                if (this.$route.params.uuid !== "new") {
                    url = url + "/" + this.$route.params.uuid;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    expiry: this.expiry + " 23:59:59",
                    permissions: this.permissions,
                    description: this.description
                }).then(response => {

                    this.loading = false;
                    if (this.$route.params.uuid === "new") {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("credentials.added")
                        }]);
                        this.hash = response.data.hash;
                        this.token = response.data.token;
                        this.uuid = response.data.uuid;
                        this.show_dialog = true;
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("credentials.updated")
                        }]);
                    }

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        expiry: {required},
        permissions: {required}
    },
    computed: {
        expiryErrors() {
            const errors = [];
            if (!this.$v.expiry.$dirty) return errors;
            !this.$v.expiry.required && errors.push(this.$t("credentials.expiry_required"));
            return errors;
        },
        permissionsErrors() {
            const errors = [];
            if (!this.$v.permissions.$dirty) return errors;
            !this.$v.permissions.required && errors.push(this.$t("credentials.permissions_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>